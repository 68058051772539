import { useEffect, useState } from "react";
import InitiativeOverviewData from "../data/InitiativeOverviewData.json";
import Immfinancialdata from "../data/IMM_financial_data.json";
import ImmfinancialdataUpdated from "../data/IMM_financial_data_updated.json";
import Immimpactdata from "../data/IMM_impact_vs_plan.json";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { CONSTANTS } from "../data/constants";
import { useSelector } from "react-redux";
import UseLocalStorage from "../hooks/useLocalStorage";

export const GenerateRowsColumn = (
  currentTab,
  sortModel,
  overviewPaginationModel
) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const selectedValue = UseLocalStorage("selectedType");
  const { overviewTableData, financialTableData } = useSelector(
    (state) => state.InitiativeSlice
  );
  const currentYear = CONSTANTS?.currentYear;
  console.log("Overview Table Data :::", overviewTableData);

  useEffect(() => {
    if (currentTab === "initiative-overview" && overviewTableData?.length) {
      setColumns([]);
      const prepareColumn = Object.keys(overviewTableData[0])
        .filter(
          (col) =>
            col.startsWith("src_id") ||
            col.startsWith("name") ||
            col === "first_month" ||
            col === "driver" ||
            col === "sector" ||
            col === "lever" ||
            col === `gp_${currentYear}` ||
            col === `gp_${currentYear + 1}` ||
            col === `gp_${currentYear + 2}` ||
            col === "total_gp" ||
            col === "country" ||
            col === "segment"
        )
        .map((col, index) => {
          if (col.startsWith("src_id")) {
            return {
              field: col,
              headerName: "ID",
              // width: 70,
              flex: 1.5,
              headerAlign: "left",
              sticky: "left",
              renderCell: (params) =>
                params?.row?.inv_type === "OPPORTUNITY" ? (
                  selectedValue === "segment" ? (
                    <div>{params.value}</div>
                  ) : (
                    <Link
                      to={`/edit-opportunity/${params.value}/${params?.row?.cal_year}`}
                    >
                      <div>{params.value}</div>
                    </Link>
                  )
                ) : selectedValue === "segment" ? (
                  <div>{params.value}</div>
                ) : (
                  <Link
                    to={`/initiative-details/${params.value}/${params?.row?.sector}/${params?.row?.driver}/${params?.row?.lever}/${params?.row?.cal_year}`}
                  >
                    <div>{params.value}</div>
                  </Link>
                ),
            };
          }
          if (col.startsWith("name")) {
            return {
              field: col,
              headerName: "Name",
              // width: 200,
              flex: 4,
              headerAlign: "left",
              sticky: "left",
              renderCell: (params) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "85%",
                      textOverflow: "ellipsis",
                      wordBreak: "break-all",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    title={params.value}
                  >
                    {params.value}
                  </div>
                  <div>
                    {/* {console.log("Checking Row Type...", params?.row?.inv_type)} */}
                    {params?.row?.inv_type.startsWith("I") ? (
                      <IconButton title="Initiative">
                        <SettingsOutlinedIcon
                          fontSize="small"
                          titleAccess="Initiative"
                        />
                      </IconButton>
                    ) : (
                      <IconButton title="Opportunity">
                        <LightbulbOutlinedIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                </div>
              ),
            };
          }
          if (col === "first_month") {
            return {
              field: col,
              headerName: "First Month Impact",
              // width: 200,
              flex: 3,
              headerAlign: "left",
              // renderCell: (params) => convertToDateString(params.value),
            };
          }
          if (col === "country") {
            return {
              field: col,
              headerName: "Country",
              // width: 200,
              flex: 2,
              headerAlign: "left",
              // renderCell: (params) => convertToDateString(params.value),
            };
          }
          if (col === "segment") {
            return {
              field: col,
              headerName: "Segment",
              // width: 200,
              flex: 2,
              headerAlign: "left",
              // renderCell: (params) => convertToDateString(params.value),
            };
          }
          if (col === "driver") {
            return {
              field: col,
              headerName: "Driver",
              // width: 200,
              flex: 2.5,
              headerAlign: "left",
              // renderCell: (params) => convertToDateString(params.value),
            };
          }
          if (col === "sector") {
            return {
              field: col,
              headerName: "Sector",
              // width: 200,
              flex: 2,
              headerAlign: "left",
              // renderCell: (params) => convertToDateString(params.value),
            };
          }
          if (col === "lever") {
            return {
              field: col,
              headerName: "Lever",
              // width: 200,
              flex: 2,
              headerAlign: "left",
              // renderCell: (params) => convertToDateString(params.value),
            };
          }
          if (col === `gp_${currentYear}`) {
            return {
              field: col,
              headerName: `Inc GP ${currentYear}`,
              // width: 200,
              // type:"number",
              flex: 2,
              headerAlign: "left",
              renderCell: (params) =>
                params.value &&
                Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(params.value),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col === `gp_${currentYear + 1}`) {
            return {
              field: `gp_${currentYear + 1}`,
              headerName: `Inc GP ${currentYear + 1}`,
              flex: 2,
              headerAlign: "left",
              renderCell: (params) =>
                params.value &&
                Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(params.value),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col === `gp_${currentYear + 2}`) {
            return {
              field: col,
              headerName: `Inc GP ${currentYear + 2}`,
              // width: 200,
              flex: 2,
              headerAlign: "left",
              renderCell: (params) =>
                params.value &&
                Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(params.value),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col === "total_gp") {
            return {
              field: col,
              headerName: "Total GP($)",
              // width: 200,
              flex: 2,
              headerAlign: "left",
              renderCell: (params) =>
                params.value &&
                Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(params.value),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2);
              },
            };
          }
          return null;
        });
      console.log("COLUMN :::", prepareColumn);
      console.log("ROWS :::", overviewTableData);

      setColumns(prepareColumn);
      // setRows(overviewTableData);
    }

    if (currentTab === "imm-financial" && financialTableData.length) {
      setColumns([]);
      const columns = Object.keys(financialTableData[0])
        .filter(
          (col) =>
            col.startsWith("src_id") ||
            col.startsWith("name") ||
            col.startsWith("total_nsv") ||
            col.startsWith("total_gm") ||
            col.startsWith("total_gm_sector") ||
            col.startsWith("total_gp") ||
            col.startsWith(`nsv_${currentYear}`) ||
            col.startsWith(`gp_${currentYear}`) ||
            col.startsWith(`nsv_${currentYear + 1}`) ||
            col.startsWith(`gp_${currentYear + 1}`) ||
            col.startsWith(`nsv_${currentYear + 2}`) ||
            col.startsWith(`gp_${currentYear + 2}`) ||
            col.startsWith(`inc_gm_total_${currentYear}`) ||
            col.startsWith(`inc_gm_sector_${currentYear}`) ||
            col.startsWith(`inc_gm_sector_${currentYear + 1}`) ||
            col.startsWith(`inc_gm_total_${currentYear + 1}`) ||
            col.startsWith(`inc_gm_sector_${currentYear + 2}`) ||
            col.startsWith(`inc_gm_total_${currentYear + 2}`) ||
            col.startsWith(`country`) ||
            col.startsWith(`segment`)
        )
        .map((col, index) => {
          if (col.startsWith("src_id")) {
            return {
              field: col,
              headerName: "ID",
              // flex: 1.5,
              width: 100,
              headerAlign: "left",
              renderCell: (params) =>
                params?.row?.inv_type === "OPPORTUNITY" ? (
                  selectedValue === "segment" ? (
                    <div>{params.value}</div>
                  ) : (
                    <Link
                      to={`/edit-opportunity/${params.value}/${params?.row?.cal_year}`}
                    >
                      <div>{params.value}</div>
                    </Link>
                  )
                ) : selectedValue === "segment" ? (
                  <div>{params.value}</div>
                ) : (
                  <Link
                    to={`/initiative-details/${params.value}/${params?.row?.sector}/${params?.row?.driver}/${params?.row?.lever}/${params?.row?.cal_year}`}
                  >
                    <div>{params.value}</div>
                  </Link>
                ),
            };
          }
          if (col.startsWith("name")) {
            return {
              field: col,
              headerName: "Name",
              // flex: 3,
              width: 250,
              renderCell: (params) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "85%",
                      textOverflow: "ellipsis",
                      wordBreak: "break-all",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {params.value}
                  </div>
                  <div>
                    {params?.row?.inv_type.startsWith("I") ? (
                      <IconButton title="Initiative">
                        <SettingsOutlinedIcon
                          fontSize="small"
                          titleAccess="Initiative"
                        />
                      </IconButton>
                    ) : (
                      <IconButton title="Opportunity">
                        <LightbulbOutlinedIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                </div>
              ),
            };
          }
          if (col.startsWith("country")) {
            return {
              field: col,
              headerName: "Country",
              // flex: 3,
              width: 120,
              renderCell: (params) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "85%",
                      textOverflow: "ellipsis",
                      wordBreak: "break-all",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {params.value}
                  </div>
                </div>
              ),
            };
          }
          if (col.startsWith("segment")) {
            return {
              field: col,
              headerName: "Segment",
              // flex: 3,
              width: 120,
              renderCell: (params) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "85%",
                      textOverflow: "ellipsis",
                      wordBreak: "break-all",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {params.value}
                  </div>
                </div>
              ),
            };
          }
          if (col.startsWith("total_nsv")) {
            return {
              field: col,
              headerName: "Inc NSV($)",
              // flex: 2.7,
              width: 110,
              renderCell: (params) => (
                <div>
                  {params.value &&
                    Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(params.value)}
                </div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2);
              },
            };
          }
          if (col.startsWith("total_gm_sector")) {
            return {
              field: col,
              headerName: "Inc GM% Sector",
              // flex: 2.7,
              width: 140,
              renderCell: (params) => (
                <div>{parseFloat(params.value).toFixed(2)}%</div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2);
              },
            };
          }
          if (col.startsWith("total_gp")) {
            return {
              field: col,
              headerName: "Inc GP($)",
              // flex: 2.7,
              width: 100,
              renderCell: (params) => (
                <div>
                  {params.value &&
                    Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(params.value)}
                </div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2);
              },
            };
          }

          if (col.startsWith("total_gm")) {
            return {
              field: col,
              headerName: "Inc GM% Country",
              // flex: 2.7,
              width: 150,
              renderCell: (params) => (
                <div>{parseFloat(params.value).toFixed(2)}%</div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2);
              },
            };
          }
          if (col.startsWith(`nsv_${currentYear}`)) {
            return {
              field: col,
              headerName: "Inc NSV($)",
              // flex: 2.7,
              width: 110,
              renderCell: (params) => (
                <div>
                  {params.value &&
                    Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(params.value)}
                </div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2);
              },
            };
          }
          if (col.startsWith(`gp_${currentYear}`)) {
            return {
              field: col,
              headerName: "Inc GP($)",
              // flex: 2.7,
              width: 100,
              renderCell: (params) => (
                <div>
                  {params.value &&
                    Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(params.value)}
                </div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`inc_gm_sector_${currentYear}`)) {
            return {
              field: col,
              headerName: "Inc GM% Sector",
              // flex: 3.2,
              width: 140,
              renderCell: (params) => (
                <div>{parseFloat(params.value).toFixed(2)}%</div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`inc_gm_total_${currentYear}`)) {
            return {
              field: col,
              headerName: "Inc GM% Country",
              // flex: 3,
              width: 150,
              renderCell: (params) => (
                <div>{parseFloat(params.value).toFixed(2)}%</div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`nsv_${currentYear + 1}`)) {
            return {
              field: col,
              headerName: "Inc NSV($)",
              // flex: 2.7,
              width: 110,
              renderCell: (params) => (
                <div>
                  {params.value &&
                    Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(params.value)}
                </div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`gp_${currentYear + 1}`)) {
            return {
              field: col,
              headerName: "Inc GP($)",
              // flex: 2.7,
              width: 100,
              renderCell: (params) => (
                <div>
                  {params.value &&
                    Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(params.value)}
                </div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`inc_gm_sector_${currentYear + 1}`)) {
            return {
              field: col,
              headerName: "Inc GM% Sector",
              // flex: 3.2,
              width: 140,
              renderCell: (params) => (
                <div>{parseFloat(params.value).toFixed(2)}%</div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`inc_gm_total_${currentYear + 1}`)) {
            return {
              field: col,
              headerName: "Inc GM% Country",
              // flex: 3,
              width: 150,
              renderCell: (params) => (
                <div>{parseFloat(params.value).toFixed(2)}%</div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`nsv_${currentYear + 2}`)) {
            return {
              field: col,
              headerName: "Inc NSV($)",
              // flex: 2.7,
              width: 100,
              // type:"number",
              renderCell: (params) => (
                <div>
                  {params.value &&
                    Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(params.value)}
                </div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`gp_${currentYear + 2}`)) {
            return {
              field: col,
              headerName: "Inc GP($)",
              // flex: 2.7,
              width: 100,
              // type: "number",
              renderCell: (params) => (
                <div>
                  {params.value &&
                    Intl.NumberFormat("de-DE", {
                      style: "decimal",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(params.value)}
                </div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`inc_gm_sector_${currentYear + 2}`)) {
            return {
              field: col,
              headerName: "Inc GM% Sector",
              // flex: 3.2,
              width: 140,
              // type: "number",
              renderCell: (params) => (
                <div>{parseFloat(params.value).toFixed(2)}%</div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }
          if (col.startsWith(`inc_gm_total_${currentYear + 2}`)) {
            return {
              field: col,
              headerName: "Inc GM% Country",
              // flex: 3,
              width: 150,
              // type: "number",
              renderCell: (params) => (
                <div>{parseFloat(params.value).toFixed(2)}%</div>
              ),
              sortComparator: (v1, v2) => {
                return Number(v1) - Number(v2); // Custom sorting logic for numbers
              },
            };
          }

          return null;
        });

      function sortColumns(columnArray) {
        const order = [
          "src_id",
          "name",
          "country",
          "segment",
          currentYear,
          currentYear + 1,
          currentYear + 2,
        ]; // Define the order of fields
        return columnArray.sort((a, b) => {
          let indexA = order.findIndex((element) => a.field.includes(element));
          let indexB = order.findIndex((element) => b.field.includes(element));
          indexA = indexA === -1 ? Number.MAX_VALUE : indexA;
          indexB = indexB === -1 ? Number.MAX_VALUE : indexB;
          return indexA - indexB;
        });
      }

      console.log(sortColumns(columns));
      // console.log(columns);
      setColumns(columns);
    }

    if (currentTab === "extended-view") {
      // Immimpactdata

      const columnNames = Object.keys(Immimpactdata[0]).reduce((cols, key) => {
        if (typeof Immimpactdata[0][key] === "object") {
          Object.keys(Immimpactdata[0][key]).forEach((subKey) => {
            cols[`${key}${subKey}`] = `${subKey}`;
            // console.log("Cols Key and SubKey :::", cols[`${key}${subKey}`]);
            console.log("Cols Key and SubKey :::", cols, key);
          });
        } else {
          cols[key] = key;
        }
        return cols;
      }, {});

      // console.log(columnNames);

      // Create columns array for MUI DataGrid
      const columns = Object.entries(columnNames).map(
        ([field, headerName]) => ({
          field,
          headerName,
          width: field === "Name" ? 185 : 90,
        })
      );

      // console.log(columns)
      // Create rows array for MUI DataGrid
      const rows = Immimpactdata.map((item) => {
        const row = { id: item.ID };
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === "object") {
            Object.keys(item[key]).forEach((subKey) => {
              row[`${key}${subKey}`] = item[key][subKey];
            });
          } else {
            row[key] = item[key];
          }
        });
        return row;
      });

      function sortColumns(columnArray) {
        const order = [
          "src_id",
          "Name",
          "country",
          "segment",
          currentYear,
          currentYear + 1,
          currentYear + 2,
        ]; // Define the order of fields
        return columnArray.sort((a, b) => {
          let indexA = order.findIndex((element) => a.field.includes(element));
          let indexB = order.findIndex((element) => b.field.includes(element));
          indexA = indexA === -1 ? Number.MAX_VALUE : indexA;
          indexB = indexB === -1 ? Number.MAX_VALUE : indexB;
          return indexA - indexB;
        });
      }

      console.log(columns);
      console.log("Column Array", sortColumns(columns));
      setColumns(columns);
      setRows(rows);

      console.log("Column:::", columns);
      console.log("Rows:::", rows);
    }
  }, [
    currentTab,
    overviewTableData,
    financialTableData,
    sortModel,
    overviewPaginationModel.page,
    overviewPaginationModel.pageSize,
  ]);

  return { rows, columns };
};
