import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  changeSelectedType,
  changeSelectedValue,
  changeSubSegment,
  fetchMarketData,
} from "../redux/reducers/CountrySegmentSlice";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
export default function GroupedSelect() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  // Redux state
  const marketData = useSelector((state) => state.MarketSlice.marketData || []);
  const marketStatus = useSelector((state) => state.MarketSlice.marketStatus);
  const { selectedValue } = useSelector((state) => state.MarketSlice);
  const handleSelectChange = (type, value, codes) => {
    dispatch(changeSelectedValue(value));
    dispatch(changeSelectedType(type));
    dispatch(changeSubSegment(codes));
    localStorage.setItem("selectedType", type);
    localStorage.setItem("selectedValue", value);
    localStorage.setItem("subSegment", codes);
    window.dispatchEvent(new Event("localStorageUpdated"));
    if (type === "segment" && location.pathname !== "/") {
      history.push("/");
    }
  };
  const handleDropdownChange = (event) => {
    const type = event.target.options[event.target.selectedIndex].dataset.type;
    const value = event.target.value;
    const codes =
      type === "segment"
        ? marketData?.segments?.find((market) => market.segment === value)
            .sub_segment
        : [];
    handleSelectChange(type, event.target.value, codes);
  };

  if (
    location.pathname === "/create-opportunity" ||
    location.pathname === "/edit-opportunity" ||
    location.pathname === "/initiative-details" ||
    location.pathname === "/create-initiatives" ||
    location.pathname.includes("/initiative-details") ||
    location.pathname.includes("/create-initiatives") ||
    location.pathname.includes("/edit-opportunity")
  ) {
    return null;
  }

  return (
    <div>
      {marketStatus === "loading" ? (
        <p>Loading...</p>
      ) : marketStatus === "failed" ? (
        <p>Failed to load data</p>
      ) : (
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel htmlFor="grouped-native-select">Select Option</InputLabel>
          <Select
            native
            value={selectedValue}
            onChange={handleDropdownChange}
            id="grouped-native-select"
            label="Select Option"
          >
            <optgroup label="Country">
              {marketData?.country?.map((country, index) => (
                <option key={index} value={country} data-type="country">
                  {country}
                </option>
              ))}
            </optgroup>
            <optgroup label="Segment">
              {marketData?.segments?.map((segment, index) => (
                <option
                  key={index}
                  value={segment?.segment}
                  data-type="segment"
                >
                  {segment.segment}
                </option>
              ))}
            </optgroup>
          </Select>
        </FormControl>
      )}
    </div>
  );
}
