import React, { useState } from "react";
import {
  Box,
  List,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Drawer,
  ListItemIcon,
} from "@mui/material";
import { ReactComponent as HomeIcon } from "../assets/images/Home.svg";
import { ReactComponent as InitiativeIcon } from "../assets/images/Initiatives Pipeline.svg";
import { ReactComponent as PlayGroundIcon } from "../assets/images/Playground.svg";
import { ReactComponent as ReportsIcon } from "../assets/images/Reports.svg";
import { ReactComponent as IMMIcon } from "../assets/images/IMM Diagnostics.svg";
import { ReactComponent as ExpandIcon } from "../assets/images/Dropdown.svg";
import { ReactComponent as CloseIcon } from "../assets/images/Close.svg";
import { useHistory } from "react-router-dom";
import "../styles/drawerList.scss"; // Import the SCSS file

const menuItems = [
  {
    title: "Home",
    content: [{ name: "Scorecard", link: "scorecard" }],
    icon: HomeIcon,
  },
  {
    title: "Initiative Pipeline",
    content: [
      { name: "Initiatives Log", link: "" },
      { name: "Create Initiatives", link: "create-initiatives" },
    ],
    icon: InitiativeIcon,
  },
  {
    title: "Playground",
    content: [
      { name: "Playground", link: "playground" },
      { name: "Create Opportunities", link: "create-opportunity" },
      // { name: "Upload Opportunities", link: "upload-opportunities" },
      // { name: "Define Targets", link: "define-targets" },
      // {
      //   name: "Define Headwinds & Tailwinds",
      //   link: "define-headwinds-tailwindds",
      // },
      // { name: "Define confidence", link: "define-confidence" },
    ],
    icon: PlayGroundIcon,
  },
  {
    title: "Reports",
    content: [
      { name: "Margin Walk Future Years", link: "margin-walk-future-years" },
      { name: "Margin Walk $GP", link: "margin-walk-gp-dollar" },
      { name: "Financial AOC vs Initiative Margin Walk", link: "financial-aoc-vs-initiative-margin-walk" },
      // { name: "Multi-year Initiatives by Lever", link: "multi-year-initiatives-by-lever" },
      { name: "Financial Margin Bridge", link: process.env.REACT_APP_FINANCIAL_MARGIN_BRIDGE_URL },
    ],
    icon: ReportsIcon,
  },
  {
    title: "IMM Diagnostics",
    content: [
      { name: "Margin Map", link: process.env.REACT_APP_MARGIN_MAP_URL },
      { name: "P&L Interrogation", link: process.env.REACT_APP_PNL_INTERROGATION_URL },
    ],
    icon: IMMIcon,
  },
];

const DrawerList = ({ setDrawerOpen }) => {
  const [open, setOpen] = useState(true);
  const isAccess = localStorage.getItem("isUserAccess");
  const history = useHistory();
  const isRGMUser = localStorage.getItem("isRGMUser")

  const handleClose = () => {
    setOpen(false);
    setDrawerOpen(false);
  };

  const handleItemClick = (link) => {
    if (link.startsWith("https")) {
      // window?.location?.replace(link);  // Replace URL
      window.open(link, "_blank"); // External Open
    } else {
      history.push(`/${encodeURIComponent(link)}`);
    }
    setDrawerOpen(false);
    handleClose();
  };

  return (
    <Drawer anchor="left" open={open} onClose={handleClose}>
      <Box role="presentation" className="drawer-list">
        <Box className="drawer-header">
          <IconButton onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
          <h1>IMM</h1>
        </Box>
        <List sx={{ p: 0, mt: 8 }}>
          {menuItems.map((item) => (
            <Accordion key={item.title} className="accordion">
              <AccordionSummary
                expandIcon={<ExpandIcon />}
                aria-controls={`${item.title}-content`}
                id={`${item.title}-header`}
                className="accordion-summary"
              >
                <ListItemIcon>
                  <item.icon
                    style={{ width: 20, height: 20, marginTop: "5px" }}
                  />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </AccordionSummary>
              <AccordionDetails
                className={`accordion-details ${item.content.length === 1 ? "single-item" : ""
                  }`}
              >
                <List sx={{ p: 0, m: 0 }}>
                  {item.content.map((subItem) => (
                    <ListItemText
                      key={subItem.name}
                      primary={subItem.name}
                      className={`list-item-text ${!isAccess ? "disabled" : ""
                        }`}
                      onClick={() => {
                        if (isAccess === "false" && subItem?.link === "create-opportunity") {
                          handleClose();
                        } else if (subItem.link === 'create-initiatives' && !isRGMUser) {
                          alert('You do not have access to this page');
                        } else {
                          handleItemClick(subItem?.link);
                        }
                      }}
                    />
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default DrawerList;
