import axios from "axios";
import apiClient from "../../axiosInstance";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const CONSTANTS = {
  currentYear: new Date().getFullYear(),
};

const initialState = {
  currentYear: CONSTANTS?.currentYear,
  yearStatus: "idle",
};
const createReducer = (key) => (state, action) => {
  state[key] = action.payload;
};
export const fetchYearData = createAsyncThunk(
  "home/yearTransition",
  async () => {
    const response = await apiClient.get("api/home/year-details");
    const data = response.data;
    return data;
  }
);
const YearTransitionSlice = createSlice({
  name: "Year",
  initialState,
  reducers: {
    //  changeYearData: createReducer("currentYear"),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchYearData.pending, (state) => {
        state.yearStatus = "loading";
        state.currentYear = CONSTANTS?.currentYear;
      })
      .addCase(fetchYearData.rejected, (state) => {
        state.yearStatus = "failed";
        state.currentYear = CONSTANTS?.currentYear;
      })
      .addCase(fetchYearData.fulfilled, (state, action) => {
        state.yearStatus = "succeeded";
        state.currentYear = action.payload.data?.CAL_YEAR;
      });
  },
});

export const { changeYearData } = YearTransitionSlice.actions;

export default YearTransitionSlice.reducer;
