import React from "react";
import InitiativeTabs from "./InitiativeTabs";
import PBIChartArea from "./PBIChartArea";
import InititativeFilters from "./InititativeFilters";
import GlobalFilter from "./GlobalFilter";
import "../../styles/screen.scss";
import UseLocalStorage from "../../hooks/useLocalStorage";
import { Backdrop, CircularProgress } from "@mui/material";


const Homepage = () => {
  const localStorageValue = UseLocalStorage("selectedType");
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="container" style={{ paddingTop: "10px" }}>
      {localStorageValue !== "segment" && (
        <>
          <GlobalFilter />
          <PBIChartArea />
        </>
      )}

      <InititativeFilters setLoading={setLoading} />
      <InitiativeTabs />
    </div>
  );
};

export default Homepage;
