import React, { useState, useEffect } from "react";
import "./CustomAlert.css"; // Add styles for your popup
import { version } from "react-dom/server";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const CustomAlert = ({ message, type, visible, onClose }) => {
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        onClose();
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, [visible, onClose]);

  if (!visible) return null;

  return (
    <div className={`custom-alert ${type}`}>
    {type === 'success' && <CheckCircleIcon className="alert-icon success-icon" />}
    {type === 'error' && <button onClick={onClose} className="close-btn">&times;</button>}
    <p>{message}</p>
  </div>
  );
};

export default CustomAlert;
