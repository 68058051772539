import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function InitiativeDataTable({ id, data }) {
  return (
    <>
      <div className="init-details-card">
        <div className="init-details-item">
          <div className="init-details-key">ID</div>
          <div className="init-details-value">{id}</div>
        </div>
        <div className="init-details-item">
          <div className="init-details-key">Name</div>
          <div className="init-details-value">{data.name}</div>
        </div>
        <div className="init-details-item">
          <div className="init-details-key">Sector</div>
          <div className="init-details-value">{data.sector}</div>
        </div>
        <div className="init-details-item">
          <div className="init-details-key">Lever</div>
          <div className="init-details-value">{data.lever}</div>
        </div>
        <div className="init-details-item">
          <div className="init-details-key">Driver</div>
          <div className="init-details-value">{data.driver}</div>
        </div>
        <div className="init-details-item">
          <div className="init-details-key">Owner</div>
          <div className="init-details-value">{data.owner}</div>
        </div>
        <div className="init-details-item">
          <div className="init-details-key">Second Owner</div>
          <div className="init-details-value">{data.delegate || null}</div>
        </div>
      </div>

      {/* Description */}
      <Accordion
        sx={{
          border: "none",
          boxShadow: "none",
          background: "none",
          margin: "10px 0",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            width: 200,
            color: "#42B19E",
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          View Description
        </AccordionSummary>
        <AccordionDetails sx={{ border: "none" }}>
          <div className="init-details-desc">
            {data.description && data.description !== "null"
              ? data.description
              : null}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
