import axios from "axios";
import apiClient from "../../axiosInstance";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  marketData: [],
  marketStatus: "idle",
  selectedType: "",
  selectedValue: "",
  segmentData: [],
  subSegment: null,
};
const createReducer = (key) => (state, action) => {
  state[key] = action.payload;
};
export const fetchMarketData = createAsyncThunk(
  "home/marketSegments",
  async (postData) => {
    const response = await apiClient.post("/api/home/fetch-countries");
    const data = response.data;
    return data;
  }
);
const MarketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    changeSegmentData: createReducer("segmentData"),
    changeSelectedType: createReducer("selectedType"),
    changeSelectedValue: createReducer("selectedValue"),
    changeSubSegment: createReducer("subSegment"),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketData.pending, (state) => {
        state.marketStatus = "loading";
        state.marketData = [];
      })
      .addCase(fetchMarketData.rejected, (state) => {
        state.marketStatus = "failed";
        state.marketData = [];
      })
      .addCase(fetchMarketData.fulfilled, (state, action) => {
        state.marketStatus = "succeeded";
        state.marketData = action.payload.data;
      });
  },
});

export const {
  changeSelectedType,
  changeSegmentData,
  changeSelectedValue,
  changeSubSegment,
} = MarketSlice.actions;

export default MarketSlice.reducer;
