import { createRoot } from "react-dom/client";
import { Snackbar, Alert } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

let containerRef = null;

const createContainer = () => {
  const container = document.createElement("div");
  document.body.appendChild(container);
  containerRef = createRoot(container);
  return containerRef;
};

const getContainer = () => {
  if (!containerRef) {
    return createContainer();
  }
  return containerRef;
};

// Icon mapping
const icons = {
  success: <CheckCircleIcon />,
  error: <ErrorIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
};

const ToastComponent = ({
  open,
  message,
  severity,
  onClose,
  autoHideDuration = 5000,
  anchorOrigin = { vertical: "top", horizontal: "right" },
  ...snackbarProps
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      {...snackbarProps}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        iconMapping={icons}
        sx={{
          width: "100%",
          minWidth:"400px",
          alignItems: "center",
          "& .MuiAlert-icon": {
            fontSize: "24px",
            marginRight: "12px",
            padding: "0px",
          },
          "& .MuiAlert-message": {
            padding: "8px 0",
            fontSize: "16px",
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

const showToast = (message, severity, options = {}) => {
  const container = getContainer();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;

    container.render(
      <ToastComponent
        open={false}
        message={message}
        severity={severity}
        onClose={handleClose}
        {...options}
      />
    );

    if (options.onClose) {
      options.onClose(event, reason);
    }
  };

  container.render(
    <ToastComponent
      open={true}
      message={message}
      severity={severity}
      onClose={handleClose}
      {...options}
    />
  );
};

export const toast = {
  success: (message, options = {}) =>
    showToast(message, "success", { sx: { bg: "#4caf50" }, ...options }),
  error: (message, options = {}) =>
    showToast(message, "error", { sx: { bg: "#f44336" }, ...options }),
  warning: (message, options = {}) => showToast(message, "warning", options),
  info: (message, options = {}) => showToast(message, "info", options),
};
