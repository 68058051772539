import React, { useState } from "react";
import { saveAs } from "file-saver";
import { CircularProgress } from "@mui/material";
import CustomAlert from "../../components/CustomAlert";

import initiativeTemplate from "../../assets/images/Initiative's_bulk_Sample.xlsx";
import opportunityTemplate from "../../assets/images/Opportunity's_bulk_Sample.xlsx";

function DownloadExcel({ name }) {
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState("");

  const downloadTableData = async () => {
    setLoadingDownload(true);

    try {
      // Determine which file to download based on `name`
      const file = name === "initiative" ? initiativeTemplate : opportunityTemplate;
      const filename = name === "initiative" 
        ? `Initiative's_Sample.xlsx` 
        : `Opportunity's_Sample.xlsx`;

      // Trigger the file download
      saveAs(file, filename);
      
      setLoadingDownload(false);
      setAlertMessage("Template downloaded successfully");
      setAlertType("success");
      setAlertVisible(true);
    } catch (error) {
      setLoadingDownload(false);
      console.error("Error while downloading template:", error);
      setAlertMessage("Failed to download the template");
      setAlertType("error");
      setAlertVisible(true);
    }
  };

  return (
    <>
      <button className="download-sample" onClick={downloadTableData}>
        Download a Template to see an example of required format{" "}
        {loadingDownload && <CircularProgress size={20} />}
      </button>
      <CustomAlert
        message={alertMessage}
        type={alertType}
        visible={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </>
  );
}

export default DownloadExcel;
