import React, { useEffect, useState } from "react";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import ElevatorOutlinedIcon from "@mui/icons-material/ElevatorOutlined";
import CustomMultiSelect from "./CustomMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  changeIsAllCategory,
  changeIsAllMarket,
  changeIsAllSector,
  changeIsAllLever,
  changeLever,
  changeCategory,
  changeMarket,
  changeSector,
} from "../../../redux/reducers/PlaygroundSlice";
import UseLocalStorage from "../../../hooks/useLocalStorage";
import "../../../styles/playground.style.scss";
import axios from "axios";

const FilterComponent = ({ currentTab }) => {
  const [sectorList, setSectorList] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [leverList, setLeverList] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const localStorageValue = UseLocalStorage("selectedValue");
  const localStorageType = UseLocalStorage("selectedType");
  const dispatch = useDispatch();
  const {
    currentSector,
    currentMarket,
    currentLever,
    currentCategory,
    isAllSector,
    isAllMarket,
    isAllLever,
    isAllCategory,
  } = useSelector((state) => state.PlaygroundSlice);

  useEffect(() => {}, [
    dispatch,
    currentSector,
    currentMarket,
    currentLever,
    currentCategory,
    isAllSector,
    isAllMarket,
    isAllLever,
    isAllCategory,
  ]);

  console.log("currentLevercurrentLever", currentLever);

  useEffect(() => {
    const idToken = localStorage.getItem("token");
    if (idToken) {
      setToken(idToken);
    }
  }, []);

  useEffect(() => {
    const fetchFiltersList = async () => {
      // const storageType =
      //   localStorageType == null ? "country" : localStorageType;
      // let filters = {
      //   [`${storageType}`]:
      //     localStorageValue === "Global" ? [] : localStorageValue,
      // };
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/playground-filters`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      console.log("444444ggggg", data);
      setSectorList(data.sector);
      setMarketList(data.market);
      setCategoryList(data.category);
    };

    const fetchPlanConfidenceFiltersList = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/playground-plan-confidence-filters`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      setMarketList(data.country);
      setLeverList(data.lever);
    };

    if (token) {
      if (currentTab === "plan-confidence") {
        fetchPlanConfidenceFiltersList();
      } else {
        fetchFiltersList();
      }
    }
  }, [currentTab && token]);

  useEffect(() => {
    setLoading(true); // Start loading before data fetch

    let filters = {
      sector: isAllSector ? [] : currentSector,
      country: [localStorageValue] || [],
      category: isAllCategory ? [] : [],
    };

    const fetchUpdatedFilterList = async () => {
      if (isAllSector) {
        setCategoryList([]);
      }
      if (!isAllSector) {
        setCategoryList([]);
      }
      setCategoryList([]);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/filters/new-playground-filters`,
          { filters },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.data.data;
        console.log("Filtered Filter List :::", data);
        if (isAllSector) {
          setCategoryList(data.category);
        }
        if (!isAllSector) {
          setCategoryList(data.category);
        }
      } finally {
        setLoading(false);
      }
    };

    let filters1 = {
      country: [localStorageValue] || [],
      lever: isAllLever ? [] : currentLever,
    };

    const fetchUpdatedPlanConfidenceFilterList = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/filters/new-playground-plan-confidence-filters`,
          { filters1 },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        const data = response.data.data;
        setMarketList(data.market);
        setLeverList(data.lever);
      } finally {
        setLoading(false); // End loading after data fetch
      }
    };

    if (token) {
      if (currentTab === "plan-confidence") {
        fetchUpdatedPlanConfidenceFilterList();
      } else {
        fetchUpdatedFilterList();
      }
    }
  }, [token, isAllSector, currentSector, isAllLever, currentLever]);

  console.log("leverList1212", leverList, isAllLever, currentLever);
  console.log("categoryList1212", categoryList, isAllCategory, currentCategory);
  return (
    <div className="init-filter-area">
      <div className="init-filter-list">
        {/* <div className="init-filter-item">
          <CustomMultiSelect
            name={"Country"}
            options={marketList}
            currentTab={currentTab}
            selectedOptions={currentMarket}
            setSelectedOptions={changeMarket}
            isAllSelected={isAllMarket}
            setIsAllSelected={changeIsAllMarket}
            loading={false}
            icon={<ElevatorOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div> */}

        {currentTab === "plan-confidence" ? (
          <div className="init-filter-item">
            <CustomMultiSelect
              name={"Lever"}
              options={leverList}
              currentTab={currentTab}
              selectedOptions={currentLever}
              setSelectedOptions={changeLever}
              isAllSelected={isAllLever}
              setIsAllSelected={changeIsAllLever}
              loading={false}
              icon={<PersonOutlinedIcon sx={{ fontSize: "20px" }} />}
            />
          </div>
        ) : (
          <>
            <div className="init-filter-item">
              <CustomMultiSelect
                name={"Sector"}
                options={sectorList}
                currentTab={currentTab}
                selectedOptions={currentSector}
                setSelectedOptions={changeSector}
                isAllSelected={isAllSector}
                setIsAllSelected={changeIsAllSector}
                loading={false}
                icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
              />
            </div>
            <div className="init-filter-item">
              <CustomMultiSelect
                name={"Category"}
                options={categoryList}
                currentTab={currentTab}
                selectedOptions={currentCategory}
                setSelectedOptions={changeCategory}
                isAllSelected={isAllCategory}
                setIsAllSelected={changeIsAllCategory}
                loading={loading}
                icon={<PersonOutlinedIcon sx={{ fontSize: "20px" }} />}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FilterComponent;
