import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#FF007F",
  "#8CFF00",
  "#00FFC5",
  "#FF6F00",
  "#C700FF",
  "#FFD300",
  "#00FFB3",
  "#FF006E",
  "#00E4FF",
  "#FF00A2",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  index,
  data,
}) => {
  const x = cx + (outerRadius + 20) * Math.cos(-midAngle * RADIAN);
  const y = cy + (outerRadius + 20) * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#000"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontSize={15}
    >
      {data[index]["allocation"] > 0 &&
        `${data[index]["category"]} ${(
          Math.round(data[index]["allocation"] * 100) / 100
        ).toFixed(0)}%`}
    </text>
  );
};

const CategoryAllocationChart = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div style={{ textAlign: "center", fontSize: "18px", marginTop: "50px" }}>
        No data available to display the chart
      </div>
    );
  }

  const numericData = data.map((d) => ({
    ...d,
    allocation: parseFloat(d.allocation),
  }));

  return (
    <PieChart width={800} height={500}>
      <Pie
        data={numericData}
        cx={350}
        cy={250}
        labelLine={true}
        label={(props) =>
          renderCustomizedLabel({ ...props, data: numericData })
        }
        outerRadius={130}
        fill="#8884d8"
        dataKey="allocation"
      >
        {numericData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default CategoryAllocationChart