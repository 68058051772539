import React, { useEffect, useState } from "react";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import SingleSelect from "./SingleSelect";
import { useSelector } from "react-redux";
import { changeMarket } from "../../../redux/reducers/ScoreCardSlice";
import CategoryFilter from "./categoryFilter";
import axios from "axios";
import UseLocalStorage from "../../../hooks/useLocalStorage";

const FilterComponent = () => {
  const [ABUlist, setABUlist] = useState([]);
  const [currentABU, setCurrentABU] = useState("All");
  const [marketList, setMarketList] = useState([]);
  const [token, setToken] = useState(null);
  const { currentMarket } = useSelector((state) => state.ScoreCardSlice);
  const localStorageType = UseLocalStorage("selectedType");
  const localStorageValue = UseLocalStorage("selectedValue");
  const marketData = useSelector((state) => state.MarketSlice.marketData || []);
  useEffect(() => {
    const idToken = localStorage.getItem("token");
    if (idToken) {
      setToken(idToken);
    }
  }, []);

  useEffect(() => {
    const storageType = localStorageType == null ? "country" : localStorageType;
    let filters = {
      [`${storageType}`]:
        localStorageValue === "Global"
          ? []
          : localStorageValue || marketData?.country?.[0],
    };
    const fetchFiltersList = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters`,
        { filters },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.data.data;
      console.log("Filter Response Data :::", data);
      setMarketList(data?.country);
    };
    if (token) {
      fetchFiltersList();
    }
  }, [token]);

  return (
    <div className="init-filter-list">
      <div className="init-filter-item-disabled">
        <SingleSelect
          name={"Segment"}
          options={ABUlist}
          selectedOptions={currentABU}
          disabled={true}
          setSelectedOptions={setCurrentABU}
          //   isAllSelected={isAllSector}
          //   setIsAllSelected={changeIsAllSector}
          icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
        />
      </div>
      {/* <div className="init-filter-item">
        <SingleSelect
          name={"Country"}
          options={marketList}
          selectedOptions={currentMarket}
          setSelectedOptions={changeMarket}
          //   isAllSelected={isAllSector}
          //   setIsAllSelected={changeIsAllSector}
          icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
        />
      </div> */}
      <CategoryFilter />
    </div>
  );
};

export default FilterComponent;
