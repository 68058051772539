import { CircularProgress } from "@mui/material";
import { loadingScreenStyle } from "../styles";

export default function FullScreenLoader() {
  return (
    <>
      (
          <div style={loadingScreenStyle}>
            <CircularProgress size={100} />
          </div>
        )
    </>
  );
}