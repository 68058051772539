import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Breadcrumbs,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Backdrop,
  Skeleton,
  Box,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation, useParams, Link } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { DataGrid } from "@mui/x-data-grid";
import "../../styles/initdetails.style.scss";
import CategoryAllocationChart from "./CategoryAllocationChart";
import FullScreenLoader from "../initiative/components/FullScreenLoader";
import apiClient from "../../axiosInstance";
import axios from "axios";
import { toast } from "../../components/toast";
import { DateRangePicker } from "rsuite";
import moment from "moment-timezone";
import {
  CLASSIFICATIONS,
  COMPLEIXTIES,
  CURRENT_YEAR,
  STATUS_OPTIONS,
  VALID_OPTIONS,
} from "../initiative/Constants";
import {
  cancelButtonStyle,
  columnStyle,
  commentPropStyle,
  deleteButtonStyle,
  inputContainerStyle,
  InputPropsStyle,
  rowStyle,
  saveButtonStyle,
  selectStyle,
  tableStyles,
  impactTabStyle,
  impactTabWrapper,
} from "../initiative/styles";
import InitiativeDataTable from "./Components/InitiativeDataTable";
import { fetchUpdatedDriver } from "../initiative/services";
import { CONSTANTS } from "../../data/constants";

const InitiativeDetails = () => {
  const { id, sector, driver, lever, year } = useParams();

  const history = useHistory();
  const idToken = localStorage.getItem("token");
  const [impactTab, setImpactTab] = useState("original");
  const [segment, setSegment] = useState("");
  const [segmentCode, setSegmentCode] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [newDriver, setDriver] = useState("");
  const [owner, setOwner] = useState("");
  const [errors, setErrors] = useState({});
  const [timeline, setTimeline] = useState([null, null]);

  const [financialOwner, setFinancialOwner] = useState("");
  const [secondOwner, setSecondOwner] = useState("");
  const [status, setStatus] = useState("NOT_STARTED");
  const [complexity, setComplexity] = useState("Medium");
  const [isValid, setIsValid] = useState("Yes");
  const [classification, setClassification] = useState("N/A");
  const [risks, setRisks] = useState("");
  const [comments, setComments] = useState("");

  const [impactRows, setImpactRows] = useState([]);
  const [impactRowsCopy, setImpactRowsCopy] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [categoryAllocationData, setCategoryAllocationData] = useState([]);
  const [categoryAllocationDataCopy, setCategoryAllocationDataCopy] = useState(
    []
  );

  const [initiativeDetailsData, setInitiativeDetailsData] = useState([]);
  const [initiativeDetailsResp, setInitiativeDetailsResp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initiativeLoading, setInitiativeLoading] = useState(false);
  const { pathname } = useLocation();

  const [totalCatPercentage, setTotalCatPercentage] = useState("");
  const [currentEditCateogry, setCurrentCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [categoryAllocationLoading, setCategoryAllocationLoading] =
    useState(false);

  const [open, setOpen] = React.useState(false);
  const [gpTotal, setGpTotal] = useState("");
  const hasAccess = localStorage.getItem("isUserAccess");
  const isRGMUser = localStorage.getItem("isRGMUser");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteText, setDeletetext] = useState("");
  const [driverList, setDrivers] = useState([]);
  const [clientTimeZone, setClientTimeZone] = useState("UTC");
  const localStorageValue = localStorage.getItem("selectedValue");
  const segmentCodeList = ["200", "400", "600"];
  const segmentList = ["IFP", "IPC"];
  const filterCategory = (data) => {
    return data.filter((category) => category.allocation > 0);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeletetext("");
  };

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setClientTimeZone(timeZone);
  }, []);

  const setFixedTimeInTimeZone = (dateString) => {
    if (!dateString) return null;
    const date = moment.tz(dateString, clientTimeZone);
    return date.set({ hour: 14, minute: 0, second: 0 }).toDate();
  };

  function formatDateToYMD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleDateSelect = useCallback((value) => {
    // validateForm();
    setTimeline(value);
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (owner?.length > 100) {
      newErrors.owner = "Owner does not exceed 100 charactors";
    }

    if (!owner) {
      newErrors.owner = "Owner is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors)?.length === 0;
  };

  const handleBlur = (e) => {
    validateForm();
  };

  const deleteInitiative = async (e) => {
    handleDeleteClose();
    setInitiativeLoading(true);
    try {
      const payload = {
        src_id: id,
        change_reason: deleteText,
        inv_type: "INITIATIVE",
        sector: sector,
        lever: lever,
        driver: driver,
        cal_year: year,
      };
      const response = await apiClient.post("/api/initiative/delete", payload);
      toast.success("Initiative deleted Successfully");

      setTimeout(() => {
        history.push("/");
      }, 2000);
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(error?.message, "error?.message");
      toast.error(
        error?.response?.data?.message ||
        "Something went wrong, Please try again!"
      );
      setInitiativeLoading(false);
    }
  };

  const calculateTotal = (data) => {
    let total = data.reduce(
      (acc, curr) => parseFloat(acc) + parseFloat(curr.allocation),
      0
    );
    return total;
  };

  const handleUpdateCategoryAllocation = (value, category) => {
    const updatedData = categoryAllocationData.map((data) =>
      data.category === category ? { ...data, allocation: value || 0 } : data
    );

    const total = calculateTotal(updatedData);
    console.log("updatedDataupdatedData12", updatedData);
    setTotalCatPercentage(total);
    setCategoryAllocationData(updatedData);
  };

  const cancelCategoryAllocation = () => {
    setCurrentCategory("");
    setCategoryAllocationData(categoryAllocationDataCopy);
    let total = calculateTotal(categoryAllocationDataCopy);
    setTotalCatPercentage(total);
    setCategoryError("");
  };

  const saveCategoryAllocation = async () => {
    setCurrentCategory("");
    if (totalCatPercentage > 100.0 || totalCatPercentage < 100.0) {
      setCategoryError("should be 100%");
    } else {
      setCategoryAllocationLoading(true);
      try {
        const data = await apiClient.post(
          "/api/initiative/category/save",
          categoryAllocationData
        );
        setCategoryAllocationDataCopy(categoryAllocationData);
        console.log("Resp after saving category allocation ::", data);
        setCurrentCategory("");
        setCategoryAllocationLoading(false);
        setCategoryError("");
        toast.success("Cateogry Allocation saved Successfully");
      } catch (error) {
        console.error("Error while saving cateogry allocation :::", error);
        setCategoryAllocationLoading(false);
        toast.error(
          error?.response?.data?.message ||
          "Something went wrong, Please try again!"
        );
      }
    }
  };

  const fetchInitiativeDetails = async () => {
    setLoading(true);

    try {
      const response = await apiClient.get(
        `/api/initiative/get-details/${id}/${sector}/${driver}/${lever}/${year}`
      );
      const data = response.data.data;

      // Update general details
      setInitiativeDetailsData(data);
      setName(data.name);
      setDescription(data.description);
      setDriver(data.driver);
      setOwner(data.owner);
      setFinancialOwner(data.financial_owner);
      setSecondOwner(data.delegate);
      setSegment(data?.segment);
      setSegmentCode(data?.ph01_segment_cd);
      setStatus(data.status || "ON_TRACK");
      setComplexity(data.complexity);
      setRisks(data.risks);
      setIsValid(data.is_valid);
      setClassification(data.classification);
      setComments(data.comments);

      if (data?.start_date && data?.end_date) {
        const initialStartDate = setFixedTimeInTimeZone(data?.start_date);
        const initialEndDate = setFixedTimeInTimeZone(data?.end_date);
        setTimeline([initialStartDate, initialEndDate]);
      }

      // Category allocation details
      fetchCategoryAllocationDetails({
        src_id: id,
        sector: data.sector,
        driver,
        lever,
        cal_year: year,
        country: data.country,
        source: data.source,
        [`original_gp_${CONSTANTS.currentYear}`]: data?.[`original_gp_${CONSTANTS.currentYear}`],
      });
      // Filter out unnecessary fields
      const filterPayload = Object.fromEntries(
        Object.entries(data).filter(
          ([key]) =>
            !["original", "impact_gm", "created_date"].some((prefix) =>
              key.startsWith(prefix)
            )
        )
      );
      setInitiativeDetailsResp(filterPayload);

      // Mapping for metrics to prefixes in data
      const metricPrefixMap = {
        "Inc NSV": "impact_nsv",
        "Inc GP($)": "impact_gp",
        "Inc. GM Sector(%)": "inc_gm_sector",
        "Inc. GM Country(%)": "inc_gm_total",
        NSV: "original_nsv",
        "GP($)": "original_gp",
        "GM Sector(%)": "org_inc_gm_sector",
        "GM Country(%)": "org_inc_gm_total",
      };

      // Function to create rows using the correct prefix for accessing data, while keeping field names without prefixes
      const createRow = (metric, field, editable = true) => {
        const prefix = metricPrefixMap[metric];
        return {
          id: field,
          metric,
          Total:
            (data[`${prefix}_${CURRENT_YEAR}`] || 0) +
            (data[`${prefix}_${CURRENT_YEAR + 1}`] || 0) +
            (data[`${prefix}_${CURRENT_YEAR + 2}`] || 0),
          [CURRENT_YEAR]: data[`${prefix}_${CURRENT_YEAR}`],
          [CURRENT_YEAR + 1]: data[`${prefix}_${CURRENT_YEAR + 1}`],
          [CURRENT_YEAR + 2]: data[`${prefix}_${CURRENT_YEAR + 2}`],
          editable,
          field,
        };
      };

      // Populate impact and original rows without prefixes in field names
      setImpactRows([
        createRow("Inc NSV", "nsv"),
        createRow("Inc GP($)", "gp"),
        createRow("Inc. GM Sector(%)", "gm sector", false),
        createRow("Inc. GM Country(%)", "gm total", false),
      ]);

      setOriginalRows([
        createRow("NSV", "nsv"),
        createRow("GP($)", "gp"),
        createRow("GM Sector(%)", "gm sector", false),
        createRow("GM Country(%)", "gm total", false),
      ]);
    } catch (error) {
      console.error("Error fetching initiative details:", error);
      toast.error(
        error?.response?.data?.message ||
        "Something went wrong, Please try again!"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchCategoryAllocationDetails = async (postdata) => {
    setCategoryAllocationLoading(true);
    try {
      const resp = await apiClient.post(
        "api/initiative/category-allocation",
        postdata
      );

      const data = resp.data.data;

      if (Array.isArray(data) && data.length === 0) {
        // Call the second API if data is empty
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/filter-category-by-sector`,
          { sector: postdata.sector },
          {
            headers: {
              authorization: `Bearer ${idToken}`,
            },
          }
        );
        let fallbackData = response.data.data;
        console.log("fallbackData", fallbackData);

        const additionalData = {
          src_id: id,
          sector: sector,
          source: postdata.source,
          driver: driver,
          cal_year: year,
          country: postdata.country,
          imp_gross_profit: postdata?.[`impact_gp${CONSTANTS.currentYear}`],
        };

        console.log("fallbackData additional", additionalData);

        // Add the additional data to each object in the fallbackData
        fallbackData = fallbackData.map((item) => ({
          ...item,
          ...additionalData, // Spread additional data fields
        }));

        console.log("fallbackData after update:", fallbackData);

        setCategoryAllocationData(fallbackData);
        setCategoryAllocationDataCopy(fallbackData);
      } else {
        let total = calculateTotal(data);
        setTotalCatPercentage(total);
        setCategoryAllocationData(data);
        setCategoryAllocationDataCopy(data);
      }
    } catch (error) {
      console.error("Error while fetching Category Allocation :::", error);
      toast.error(
        error?.response?.data?.message ||
        "Something went wrong, Please try again!"
      );
    } finally {
      setCategoryAllocationLoading(false);
    }
  };

  useEffect(() => {
    fetchInitiativeDetails();
    // preload driver options for lever - RGM
    fetchUpdatedDriver({ sector: [], lever: ["RGM"], country: [localStorageValue] })
      .then((data) => {
        const filteredDrivers = data.driver.filter(
          (driver) => driver !== "Sustaining"
        );
        setDrivers(filteredDrivers);
      })
      .catch((error) => {
        console.error("Error while fetching updated driver :::", error);
        toast.error(
          error?.response?.data?.message || "Error fetching drivers!"
        );
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const filteredData = impactRows.find((item) => item.field === "gp");
    const gpTotal = filteredData && filteredData.Total;
    setGpTotal(parseInt(gpTotal));
  }, [impactRows]);

  const updateInitiativeDetails = async () => {
    setLoading(true);
    handleClose();

    if (!validateForm()) {
      toast.error("Please fill all the mandatory fields!");
      setLoading(false);
      return;
    }

    try {
      const payload = {
        ...initiativeDetailsResp,
        name,
        description: description,
        sector: sector,
        lever: lever,
        driver: newDriver,
        owner: owner === "null" ? "" : owner,
        delegate: secondOwner,
        segment: segment,
        country: localStorageValue,
        ph01_segment_cd: segmentCode,
        classification: classification,
        complexity: complexity,
        is_valid: isValid,
        comments: comments,
        start_date: timeline[0] === null ? "" : formatDateToYMD(timeline[0]),
        end_date: timeline[1] === null ? "" : formatDateToYMD(timeline[1]),
        status: status,
        financial_owner: financialOwner,
        risks: risks,
      };

      await apiClient.post("api/initiative/save", payload);
      window.scrollTo(0, 0);
      toast.success("Initiative Details saved Successfully");
      fetchInitiativeDetails();
    } catch (error) {
      console.error("Error while saving initiative details ::", error);
      toast.error(
        error?.response?.data?.message ||
        "Something went wrong, Please try again!"
      );
    } finally {
      setLoading(false);
    }
  };

  const breadcrumbs = [
    <Link to={"/"} underline="hover" key="1" color="inherit">
      Home
    </Link>,
    <Typography key="3" color="text.primary" fontSize={14}>
      Initiative
    </Typography>,
  ];

  const columns = useMemo(
    () => [
      {
        field: "metric",
        headerName: "",
        flex: 1,
        align: "center",
        renderCell: (params) => (
          <div style={{ fontWeight: 600 }}>{params.value}</div>
        ),
      },
      {
        field: "Total",
        headerName: "Total",
        flex: 1,
        align: "center",
        type: "number",
        renderCell: (params) => (
          <div style={{ fontWeight: 600 }}>
            {["gm sector", "gm total"].includes(params.row.field) ? (
              <div>{parseFloat(params.value).toFixed(2)}%</div>
            ) : (
              Intl.NumberFormat("de-DE", {
                style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(parseFloat(params.value))
            )}
          </div>
        ),
      },
      {
        field: `${CURRENT_YEAR}`,
        headerName: `${CURRENT_YEAR}`,
        flex: 2,
        align: "center",
        type: "number",
        editable: impactTab === "original",
        renderCell: (params) => (
          <div>
            {["gm sector", "gm total"].includes(params.row.field) ? (
              <div>{parseFloat(params.value).toFixed(2)}%</div>
            ) : (
              Intl.NumberFormat("de-DE", {
                style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(params.value == null ? 0 : params.value)
            )}
            {params?.row.editable && impactTab === "original" && (
              <IconButton
                onClick={() =>
                  params.api.startCellEditMode({
                    id: params.id,
                    field: `${CURRENT_YEAR}`,
                  })
                }
              >
                <EditNoteIcon />
              </IconButton>
            )}
          </div>
        ),
      },
      {
        field: `${CURRENT_YEAR + 1}`,
        headerName: `${CURRENT_YEAR + 1}`,
        flex: 2,
        align: "center",
        type: "number",
        editable: impactTab === "original",
        renderCell: (params) => (
          <div>
            {["gm sector", "gm total"].includes(params.row.field) ? (
              <div>{parseFloat(params.value).toFixed(2)}%</div>
            ) : (
              Intl.NumberFormat("de-DE", {
                style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(params.value == null ? 0 : params.value)
            )}
            {params?.row.editable && impactTab === "original" && (
              <IconButton
                onClick={() =>
                  params.api.startCellEditMode({
                    id: params.id,
                    field: `${CURRENT_YEAR + 1}`,
                  })
                }
              >
                <EditNoteIcon />
              </IconButton>
            )}
          </div>
        ),
      },
      {
        field: `${CURRENT_YEAR + 2}`,
        headerName: `${CURRENT_YEAR + 2}`,
        flex: 2,
        align: "center",
        type: "number",
        editable: impactTab === "original",
        renderCell: (params) => (
          <div>
            {["gm sector", "gm total"].includes(params.row.field) ? (
              <div>{parseFloat(params.value).toFixed(2)}%</div>
            ) : (
              Intl.NumberFormat("de-DE", {
                style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(params.value == null ? 0 : params.value)
            )}
            {params?.row.editable && impactTab === "original" && (
              <IconButton
                onClick={() =>
                  params.api.startCellEditMode({
                    id: params.id,
                    field: `${CURRENT_YEAR + 2}`,
                  })
                }
              >
                <EditNoteIcon />
              </IconButton>
            )}
          </div>
        ),
      },
    ],
    [impactTab]
  );

  const handleCellDoubleClick = (params, event) => {
    event.stopPropagation();
  };

  const compareObject = (obj1, obj2, commonFieldName) => {
    const result = {};
    if (
      obj1[commonFieldName] &&
      obj2[commonFieldName] &&
      obj1[commonFieldName] === obj2[commonFieldName]
    ) {
      result[commonFieldName] = obj1[commonFieldName];
    }

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && key !== commonFieldName) {
        if (!obj1.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
          result[`impact_${commonFieldName}_${key}`] = obj2[key];
        }
      }
    }
    return result;
  };

  const processRowUpdate = (newRow, oldRow) => {
    // const updatedRows = impactRows.map((row) => {
    //   if (row.id === newRow.id) {
    //     const updatedValues = [
    //       CURRENT_YEAR,
    //       CURRENT_YEAR + 1,
    //       CURRENT_YEAR + 2,
    //     ].map((year) => parseFloat(newRow?.[year] || 0));
    //     const updateTotal = updatedValues.reduce((acc, val) => acc + val, 0);
    //     return { ...newRow, Total: updateTotal };
    //   }
    //   return row;
    // });

    const updatedRows = impactRows.map((row) => {
      if (row.id === newRow.id) {
        const updated2024 =
          newRow?.[`${CURRENT_YEAR}`] === null
            ? 0
            : parseFloat(newRow?.[`${CURRENT_YEAR}`]);
        const updated2025 =
          newRow?.[`${CURRENT_YEAR + 1}`] === null
            ? 0
            : parseFloat(newRow?.[`${CURRENT_YEAR + 1}`]);
        const updated2026 =
          newRow?.[`${CURRENT_YEAR + 2}`] === null
            ? 0
            : parseFloat(newRow?.[`${CURRENT_YEAR + 2}`]);
        let updateTotal = updated2024 + updated2025 + updated2026;
        let updateRow = { ...newRow, Total: updateTotal };
        newRow = updateRow;
        console.log("NEW UPDATED ROW ::", updateRow);
        return updateRow;
      } else {
        return row;
      }
    });
    setImpactRows(updatedRows);

    const updatedObj = compareObject(oldRow, newRow, newRow.field);
    setInitiativeDetailsResp((prev) => ({ ...prev, ...updatedObj }));

    return newRow;
  };

  const handleIsCellEditable = (params) => {
    return params.colDef.editable && params.row.editable;
  };

  return (
    <div className="container-fullpage" style={{ position: "relative" }}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={initiativeLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Loader */}
      {loading && <FullScreenLoader />}

      <div className="init-details-page">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginTop: "10px", fontSize: 14 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <div style={{ marginTop: "10px", borderBottom: "1px solid #BFBFBF" }} />
        <h2 className="init-details-header">Initiative Details</h2>

        {/* Display Data */}
        {loading ? (
          <Box sx={{ width: "99%", mx: "auto" }}>
            <Skeleton variant="rectangular" animation="wave" height={40} />
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={40}
              sx={{ mt: 0.5 }}
            />
          </Box>
        ) : isRGMUser === "true" && lever === "RGM" ? (
          <>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type-mand">Initiative ID</label>
                  <input
                    type="text"
                    disabled
                    value={id ? id : "Initiative ID"}
                    className="input-style"
                  />
                </div>
              </div>

              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Initiative Name</label>
                  <TextField
                    // onBlur={handleBlur}
                    type="text"
                    placeholder="Initiative name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    InputProps={{
                      sx: InputPropsStyle,
                      endAdornment: (
                        <InputAdornment position="end">
                          <EditNoteIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  ...columnStyle,
                  gridColumn: "span 2",
                }}
              >
                <div style={inputContainerStyle}>
                  <label className="label-type">Description</label>
                  <TextField
                    type="text"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    InputProps={{
                      sx: InputPropsStyle,
                      endAdornment: (
                        <InputAdornment position="end">
                          <EditNoteIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Country</label>
                  <input
                    type="text"
                    disabled
                    value={localStorageValue ? localStorageValue : "Country"}
                    className="input-style"
                  />
                </div>
              </div>
              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Segment</label>
                  <FormControl
                    sx={{
                      minWidth: 150,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    size="small"
                  >
                    <Select
                      labelId="segment"
                      id="segment"
                      variant="standard"
                      value={segment}
                      onChange={(e) => setSegment(e.target.value)}
                      sx={selectStyle}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>Select</em>;
                        }
                        return selected;
                      }}
                    >
                      {segmentList.map((segment) => (
                        <MenuItem key={segment} value={segment}>
                          {segment}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Segment Code</label>
                  <FormControl
                    sx={{
                      minWidth: 150,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    size="small"
                  >
                    <Select
                      labelId="Segment Code"
                      id="Segment Code"
                      variant="standard"
                      value={segmentCode}
                      onChange={(e) => setSegmentCode(e.target.value)}
                      sx={selectStyle}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>Select</em>;
                        }
                        return selected;
                      }}
                    >
                      {segmentCodeList.map((segmentCode) => (
                        <MenuItem key={segmentCode} value={segmentCode}>
                          {segmentCode}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div> */}
              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Lever</label>
                  <FormControl sx={{ minWidth: 150 }}>
                    <Select
                      labelId="lever"
                      id="lever"
                      variant="standard"
                      value={lever}
                      sx={selectStyle}
                    >
                      <MenuItem value={lever}>{lever}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Driver</label>
                  <FormControl sx={{ minWidth: 150 }}>
                    <Select
                      labelId="driver"
                      id="driver"
                      variant="standard"
                      value={newDriver}
                      onChange={(e) => setDriver(e.target.value)}
                      sx={selectStyle}
                    >
                      {/* {driverList.map((driver) => ( */}
                      <MenuItem key={driver} value={driver}>
                        {driver}
                      </MenuItem>
                      {/* ))} */}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Sector</label>
                  <FormControl sx={{ minWidth: 150 }}>
                    <Select
                      labelId="sector"
                      id="sector"
                      variant="standard"
                      value={sector}
                      sx={selectStyle}
                    >
                      <MenuItem value={sector}>{sector}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Owner Name</label>
                  <TextField
                    type="text"
                    placeholder="Owner name"
                    value={owner}
                    onChange={(e) => setOwner(e.target.value)}
                    onBlur={handleBlur}
                    InputProps={{
                      sx: InputPropsStyle,
                      endAdornment: (
                        <InputAdornment position="end">
                          <EditNoteIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.owner && (
                    <span style={{ color: "red" }}>{errors.owner}</span>
                  )}
                </div>
              </div>

              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Second Owner Name</label>
                  <TextField
                    type="text"
                    placeholder="Second owner name"
                    value={secondOwner}
                    onChange={(e) => setSecondOwner(e.target.value)}
                    InputProps={{
                      sx: InputPropsStyle,
                      endAdornment: (
                        <InputAdornment position="end">
                          <EditNoteIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div style={columnStyle}>
                <div style={inputContainerStyle}>
                  <label className="label-type">Timeline</label>

                  <DateRangePicker
                    onBlur={handleBlur}
                    value={timeline}
                    format="dd/MM/yyyy"
                    height="50px"
                    sx={{ border: "1px solid #BBC9FB", width: "900px" }}
                    onChange={handleDateSelect}
                    showOneCalendar={true}
                    ranges={[]}
                    onOk={(value) => setTimeline(value)}
                    cleanable={false}
                  />
                  {errors.timeline && (
                    <span style={{ color: "red" }}>{errors.timeline}</span>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <InitiativeDataTable id={id} data={initiativeDetailsData} />
        )}

        <div style={{ marginTop: "10px", borderBottom: "1px solid #BFBFBF" }} />
        <div style={{ marginTop: "10px" }}>
          <div style={impactTabWrapper}>
            <button
              style={{
                ...impactTabStyle,
                color: impactTab === "imm" ? "#000" : "#999",
              }}
              onClick={() => setImpactTab("imm")}
            >
              IMM Impact
            </button>
            <button
              style={{
                ...impactTabStyle,
                color: impactTab !== "imm" ? "#000" : "#999",
              }}
              onClick={() => setImpactTab("original")}
            >
              Original Impact
            </button>
          </div>
          <div style={{ margin: "20px 0" }}>
            <DataGrid
              rows={impactTab === "imm" ? impactRows : originalRows}
              columns={columns}
              pageSize={5}
              loading={impactRows?.length < 1}
              columnHeaderHeight={30}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              autoHeight
              hideFooter
              hideFooterSelectedRowCount
              disableColumnFilter
              disableColumnResize
              disableColumnSorting
              disableColumnMenu
              isCellEditable={handleIsCellEditable}
              onCellDoubleClick={handleCellDoubleClick}
              processRowUpdate={processRowUpdate}
              experimentalFeatures={{ newEditingApi: true }}
              sx={tableStyles}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "10px",
            borderBottom: "1px solid #BFBFBF",
          }}
        />
        <div style={{ marginTop: "10px" }}>
          <h4>Category Allocation</h4>
          <div
            style={{
              background: "#fff",
              borderRadius: "8px",
              width: "100%",
              height: "350px",
              boxShadow: "0px 4px 16px 0px #3241FF29",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            {categoryAllocationLoading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CategoryAllocationChart
                    data={filterCategory(categoryAllocationData)}
                  />
                </div>
                <div
                  style={{
                    height: "330px",
                    borderRight: "1px solid #BFBFBF",
                  }}
                />
                <div
                  style={{
                    width: "40%",
                    margin: "0 50px",
                    height: "300px",
                    overflow: "none",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  {categoryAllocationData.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40%", fontWeight: "bold" }}>
                          Category
                        </div>
                        <div
                          style={{
                            width: "40%",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          Percentage
                        </div>
                        <div
                          style={{
                            width: "20%",
                            fontWeight: "bold",
                            marginRight: "10px",
                            textAlign: "right",
                          }}
                        >
                          Amount
                        </div>
                      </div>
                      {categoryAllocationData.length > 0 &&
                        categoryAllocationData.map((data) => (
                          <div
                            key={data.category}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: "40%", fontSize: 14 }}>
                              {data.category}
                            </div>
                            <div
                              style={{
                                width: "40%",
                                textAlign: "right",
                                fontSize: 14,
                              }}
                            >
                              {currentEditCateogry !== data.category ? (
                                `${(
                                  Math.round(data.allocation * 100) / 100
                                ).toFixed(0)}%`
                              ) : (
                                <>
                                  <input
                                    type="number"
                                    value={data.allocation}
                                    onBlur={() => setCurrentCategory(null)}
                                    onChange={(e) =>
                                      handleUpdateCategoryAllocation(
                                        e.target.value,
                                        data.category
                                      )
                                    }
                                    style={{ width: "100px", fontSize: 14 }}
                                  />
                                  %
                                </>
                              )}
                              <IconButton
                                onClick={() =>
                                  setCurrentCategory(data.category)
                                }
                              >
                                <EditNoteIcon />
                              </IconButton>
                            </div>
                            <div
                              style={{
                                width: "20%",
                                marginRight: "10px",
                                textAlign: "right",
                                fontSize: 14,
                              }}
                            >
                              {Intl.NumberFormat("de-DE", {
                                style: "decimal",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format((data.allocation / 100) * gpTotal)}
                            </div>
                          </div>
                        ))}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40%", fontWeight: "bold" }}>
                          Total{" "}
                          <span style={{ color: "red" }}>
                            {categoryError && `(${categoryError})`}{" "}
                          </span>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            fontWeight: "bold",
                            textAlign: "right",
                            marginRight: "50px",
                          }}
                        >
                          {Math.round((totalCatPercentage * 100) / 100).toFixed(
                            0
                          )}
                          %
                        </div>
                        <div style={{ width: "20%" }}></div>
                      </div>

                      {(isRGMUser === "true" && lever == "RGM") ||
                        hasAccess === "true" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginTop: "15px",
                            marginRight: "10px",
                          }}
                        >
                          <button
                            style={{
                              background: "none",
                              border: "1px solid #bababa",
                              marginRight: "5px",
                              color: "#bababa",
                              borderRadius: "5px",
                              padding: "3px 7px",
                              fontSize: 16,
                              cursor: "pointer",
                            }}
                            onClick={cancelCategoryAllocation}
                          >
                            Cancel
                          </button>
                          <button
                            style={{
                              background: "#2280EF",
                              border: "none",
                              color: "#fff",
                              borderRadius: "5px",
                              padding: "3px 7px",
                              fontSize: 16,
                              cursor: "pointer",
                            }}
                            onClick={saveCategoryAllocation}
                          >
                            Save
                          </button>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        <div style={{ marginTop: "10px", borderBottom: "1px solid #BFBFBF" }} />

        <h4>More Information</h4>
        <div
          style={{
            ...rowStyle,
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          }}
        >
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Financial Owner</label>
              <TextField
                type="text"
                placeholder="Financial Owner name"
                value={financialOwner}
                onChange={(e) => setFinancialOwner(e.target.value)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Complexity</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="complexity"
                  id="complexity"
                  variant="standard"
                  value={complexity}
                  onChange={(e) => setComplexity(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {COMPLEIXTIES.map((difficulty) => (
                    <MenuItem key={difficulty} value={difficulty}>
                      {difficulty}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Status</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="status"
                  id="status"
                  variant="standard"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  sx={selectStyle}
                  // displayEmpty
                  MenuProps={{
                    PopoverClasses: {
                      paper: "custom-select-popover-init-details",
                    },
                  }}
                >
                  {STATUS_OPTIONS.map((status) => (
                    <MenuItem
                      className="init-details-menu"
                      key={status.value}
                      value={status.value}
                    >
                      <FiberManualRecordIcon
                        sx={{ padding: "2px", color: status.color }}
                      />
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Classification</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="classification"
                  id="classification"
                  variant="standard"
                  value={classification}
                  onChange={(e) => setClassification(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {CLASSIFICATIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Is Valid</label>
              <FormControl
                sx={{
                  minWidth: 150,
                  display: "flex",
                  flexDirection: "column",
                  height: "50px",
                }}
                size="small"
              >
                <Select
                  labelId="is-valid"
                  id="is-valid"
                  variant="standard"
                  value={isValid}
                  onChange={(e) => setIsValid(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {VALID_OPTIONS.map((report) => (
                    <MenuItem key={report} value={report}>
                      {report}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        {/* <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "18%",
              position: "relative",
            }}
          >
            <label
              htmlFor="tags"
              style={{
                fontSize: "16px",
                color: "#373B5C",
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Tags
            </label>
            <input
              id="tags"
              disabled
              placeholder="Add tags here..."
              style={{
                padding: 10,
                fontSize: 16,
                borderRadius: 5,
                border: "1px solid #BBC9FB",
                outline: "none",
              }}
            />
          </div> */}
        <Box style={columnStyle} mt={2}>
          <Box className="init-details-form">
            <label className="label-type" style={{ display: "block" }}>
              Comments
            </label>
            <TextField
              id="comments"
              placeholder="Add comments..."
              value={comments}
              fullWidth
              onChange={(e) => setComments(e.target.value)}
              InputProps={{
                sx: commentPropStyle,
                endAdornment: (
                  <InputAdornment position="end">
                    <EditNoteIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box style={columnStyle} mt={2}>
          <Box className="init-details-form">
            <label className="label-type" style={{ display: "block" }}>
              Risk
            </label>
            <TextField
              id="risk"
              placeholder="Add risk..."
              value={risks}
              fullWidth
              onChange={(e) => setRisks(e.target.value)}
              InputProps={{
                sx: commentPropStyle,
                endAdornment: (
                  <InputAdornment position="end">
                    <EditNoteIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: 20,
          }}
        >
          {(isRGMUser === "true" && lever == "RGM") || hasAccess === "true" ? (
            <>
              <button
                style={saveButtonStyle("true")}
                // disabled={hasAccess === "false"}
                onClick={handleClickOpen}
              >
                Save
              </button>
              <button
                style={cancelButtonStyle}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
            </>
          ) : null}
          {isRGMUser === "true" && lever === "RGM" ? (
            <button
              onClick={handleDeleteClickOpen}
              // disabled={hasAccess === "false"}
              style={deleteButtonStyle("true")}
            >
              Delete
            </button>
          ) : null}
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to save current changes ?
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={updateInitiativeDetails}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "80%",
            maxWidth: "800px",
            margin: "auto",
            padding: "20px",
          },
        }}
      >
        <DialogTitle sx={{ paddingLeft: "0px" }}>
          Are you sure you want to delete initiative?
        </DialogTitle>
        <label className="label-type-mand">Reason</label>
        <TextField
          value={deleteText}
          placeholder="Please add Reason to delete Initiative"
          onChange={(e) => setDeletetext(e.target.value)}
        />
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={deleteInitiative}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InitiativeDetails;
