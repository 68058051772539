import React, { useEffect, useState } from "react";
import axios from "axios";
import FilterItem from "./components/FilterItem";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import ElevatorOutlinedIcon from "@mui/icons-material/ElevatorOutlined";
import { Button } from "@mui/material";
import CustomMultiSelect from "./components/CustomMultiSelect";
import MultiSelect from "./components/MultiSelect";
import MoreFilterComponent from "./components/MoreFilterComponent";
import SingleSelect from "./components/SingleSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDriver,
  changeIsAllDriver,
  changeIsAllLever,
  changeIsAllSector,
  changeLever,
  changeSector,
  changeIsAllCountry,
  changeIsAllSegment,
  changeSegment,
  changeCountry,
  changeCountryList,
  changeDriverList,
  changeSectorList,
  changeLeverList,
  changeSegmentList,
  changeFilterData,
} from "../../redux/reducers/InitiativeSlice";
import "../../styles/filter.style.scss";
import {
  fetchInitiativeFinancialData,
  fetchInitiativeOverviewData,
  handleOverviewPagination,
  changeSelectedClassification,
  changeSelectedComplexity,
  changeSelectedFinancialOwner,
  changeSelectedGPCurrentYearMax,
  changeSelectedGPCurrentYearMin,
  changeSelectedGPNextYearMax,
  changeSelectedGPNextYearMin,
  changeSelectedGPYearAfterNextMax,
  changeSelectedGPYearAfterNextMin,
  changeSelectedInitiative,
  changeSelectedIsValid,
  changeSelectedLog,
  changeSelectedOwner,
  changeSelectedSecondOwner,
  changeSelectedStatus,
  changeSelectedTags,
} from "../../redux/reducers/InitiativeSlice";
import { CONSTANTS } from "../../data/constants";
import UseLocalStorage from "../../hooks/useLocalStorage";

const InititativeFilters = () => {
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = useState(null);
  const currentYear = CONSTANTS?.currentYear;
  const localStorageType = UseLocalStorage("selectedType");
  const localStorageValue = UseLocalStorage("selectedValue");
  const logsList = [
    { value: "All", type: "All" },
    { value: "OPPORTUNITY", type: "Opportunity" },
    { value: "INITIATIVE", type: "Initiative" },
  ];

  const dispatch = useDispatch();
  const {
    currentSector,
    currentCategorySector,
    currentLever,
    currentDriver,
    currentCountry,
    currentSegment,
    isAllSector,
    selectedLog,
    isAllLever,
    isAllCountry,
    isAllSegment,
    isAllDriver,
    currentTab,
    overviewPaginationModel,
    sectorList,
    segmentList,
    countryList,
    leverList,
    driverList,
  } = useSelector((state) => {
    console.log(state.InitiativeSlice, "initiativeSlice"); // Debug the state
    return state.InitiativeSlice;
  });
  const marketData = useSelector((state) => state.MarketSlice.marketData || []);
  useEffect(() => {}, [
    dispatch,
    currentSector,
    currentCategorySector,
    currentLever,
    currentDriver,
    currentCountry,
    currentSegment,
    isAllSector,
    isAllLever,
    isAllCountry,
    isAllSegment,
    isAllDriver,
  ]);

  useEffect(() => {
    const idToken = localStorage.getItem("token");
    if (idToken) {
      setToken(idToken);
    }
  }, []);

  useEffect(() => {
    const fetchFiltersList = async () => {
      const storageType =
        localStorageType == null ? "country" : localStorageType;
      let filters = {
        [`${storageType}`]:
          localStorageValue === "Global"
            ? []
            : localStorageValue || marketData?.country?.[0],
      };
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/filters`,
          { filters },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.data.data;
        dispatch(changeFilterData(data));
        console.log("Filter Response Data :::", data);
        dispatch(changeSegment([]));
        dispatch(changeCountry([]));
        dispatch(changeIsAllSegment(true));
        dispatch(changeIsAllCountry(true));
        dispatch(changeSectorList(data.sector));
        dispatch(changeLeverList(data.lever));
        dispatch(changeDriverList(data.driver));
        dispatch(changeCountryList(data?.country ?? []));
        dispatch(changeSegmentList(data?.segment ?? []));
      } catch (error) {
        console.error("Error fetching filters:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    if (token) {
      setLoading(false);
      if (localStorageValue !== "undefined") {
        fetchFiltersList();
      }
    }
  }, [marketData, localStorageValue]);

  useEffect(() => {
    const storageType = localStorageType == null ? "country" : localStorageType;
    const dynamicKey = localStorageType === "country" ? "segment" : "country";
    const dynamicValue =
      localStorageType === "country" ? currentSegment : currentCountry;
    let filters = {
      sector: isAllSector ? sectorList : currentSector,
      lever: isAllLever ? leverList : currentLever,
      [dynamicKey]:
        dynamicKey === "country"
          ? !isAllCountry
            ? dynamicValue.length && dynamicValue
            : countryList
          : !isAllSegment
          ? dynamicValue.length && dynamicValue
          : segmentList,
      [`${storageType}`]:
        localStorageValue === "Global"
          ? []
          : localStorageValue || marketData?.country?.[0],
    };

    const fetchUpdatedFilterList = async () => {
      if (isAllLever) {
        dispatch(changeLeverList([]));
        dispatch(changeDriverList([]));
      }
      if (!isAllLever) {
        dispatch(changeDriverList([]));
      }
      dispatch(changeDriverList([]));
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/new`,
        { filters },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.data.data;
      console.log("Filtered Filter List :::", data);
      if (isAllLever) {
        dispatch(changeLeverList(data.lever));
        dispatch(changeDriverList(data.driver));
      }
      if (!isAllLever) {
        dispatch(changeDriverList(data.driver));
      }
    };
    if (token) {
      fetchUpdatedFilterList();
    }
  }, [isAllSector, currentSector, isAllLever, currentLever]);

  const handleclear = () => {
    const dynamicKey = localStorageType === "country" ? "segment" : "country";
    let newSectorOptions;
    let newLeverOptions;
    let newDriverOptions;
    let newSegmentOptions = [];
    let newCountryOptions = [];
    if (isAllSector) {
      newSectorOptions = [];
      dispatch(changeIsAllSector(false));
    } else {
      newSectorOptions = sectorList;
      dispatch(changeIsAllSector(true));
    }
    if (isAllDriver) {
      dispatch(changeIsAllDriver(false));
    } else {
      dispatch(changeIsAllDriver(true));
    }
    if (isAllLever) {
      newLeverOptions = [];
      dispatch(changeIsAllLever(false));
    } else {
      newLeverOptions = leverList;
      dispatch(changeIsAllLever(true));
    }
    if (isAllSegment) {
      dispatch(changeIsAllSegment(false));
    } else {
      dispatch(changeIsAllSegment(true));
    }
    if (isAllCountry) {
      dispatch(changeIsAllCountry(false));
    } else {
      dispatch(changeIsAllCountry(true));
    }
    dispatch(changeSector(newSectorOptions));
    dispatch(changeLever(newLeverOptions));
    dispatch(changeDriver(newDriverOptions));
    dispatch(changeCountry(newCountryOptions));
    dispatch(changeSegment(newSegmentOptions));
    dispatch(changeSelectedGPCurrentYearMin(0));
    dispatch(changeSelectedGPCurrentYearMax(0));
    dispatch(changeSelectedGPNextYearMin(0));
    dispatch(changeSelectedGPNextYearMax(0));
    dispatch(changeSelectedGPYearAfterNextMin(0));
    dispatch(changeSelectedGPYearAfterNextMax(0));
    dispatch(changeSelectedInitiative([]));
    dispatch(changeSelectedOwner([]));
    dispatch(changeSelectedSecondOwner([]));
    dispatch(changeSelectedFinancialOwner([]));
    dispatch(changeSelectedTags([]));
    dispatch(changeSelectedStatus([]));
    // dispatch(changeSelectedStatus(["NOT_STARTED", "ON_TRACK", "OFF_TRACK"]));
    dispatch(changeSelectedIsValid("All"));
    dispatch(changeSelectedComplexity(""));
    dispatch(changeSelectedClassification(""));
    dispatch(changeSelectedLog("All"));
    let filterObj = {
      sector: [],
      lever: [],
      driver: [],
      inv_type: ["OPPORTUNITY", "INITIATIVE"],
      [`${localStorageType}`]:
        localStorageValue === "Global" ? [] : [localStorageValue],
      [dynamicKey]: dynamicKey === "country" ? countryList : segmentList,
    };

    let filterData = {
      pageNo: 1,
      limit: overviewPaginationModel?.pageSize,
      sortBy: `gp_${currentYear}`,
      direction: "desc",
      filters: filterObj,
    };
    if (currentTab == "initiative-overview") {
      dispatch(fetchInitiativeOverviewData(filterData));
    }
    if (currentTab == "imm-financial") {
      dispatch(fetchInitiativeFinancialData(filterData));
    }
  };

  return (
    <div className="init-filter-area">
      <div className="init-filter-list" style={{ justifyContent: "center" }}>
        <div className="init-filter-item">
          {localStorageType === "segment" ? (
            <CustomMultiSelect
              name={"Country"}
              options={countryList}
              selectedOptions={currentCountry}
              setSelectedOptions={changeCountry}
              isAllSelected={isAllCountry}
              setIsAllSelected={changeIsAllCountry}
              icon={<PersonOutlinedIcon sx={{ fontSize: "20px" }} />}
              loading={loading}
            />
          ) : (
            <CustomMultiSelect
              name={"Segment"}
              options={segmentList}
              selectedOptions={currentSegment}
              setSelectedOptions={changeSegment}
              isAllSelected={isAllSegment}
              setIsAllSelected={changeIsAllSegment}
              icon={<PersonOutlinedIcon sx={{ fontSize: "20px" }} />}
              loading={loading}
            />
          )}
        </div>
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Sector"}
            options={sectorList}
            selectedOptions={currentSector}
            setSelectedOptions={changeSector}
            isAllSelected={isAllSector}
            setIsAllSelected={changeIsAllSector}
            icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Lever"}
            options={leverList}
            selectedOptions={currentLever}
            setSelectedOptions={changeLever}
            isAllSelected={isAllLever}
            setIsAllSelected={changeIsAllLever}
            icon={<ElevatorOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Driver"}
            options={driverList}
            selectedOptions={currentDriver}
            setSelectedOptions={changeDriver}
            isAllSelected={isAllDriver}
            setIsAllSelected={changeIsAllDriver}
            icon={<PersonOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <SingleSelect
            name={"Type"}
            options={logsList}
            selectedOptions={selectedLog}
            setSelectedOptions={changeSelectedLog}
            icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <MoreFilterComponent
            name={"More Filters"}
            options="demo "
            selectedOptions="asasas"
            // list={["All"]}
            // data={["All"]}
            // setData={setDriver}
            icon={<FilterListOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        <Button
          sx={{
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "0 2px 5px 0 #dae8ff !important",
            color: "#373b5c",
            fontSize: "12px",
            fontWeight: 500,
            height: "52px",
            marginRight: "10px",
            padding: "6px 8px",
            width: "180px",
            "&:hover": {
              background: "#f0f0f0", // Optional hover effect
            },
          }}
          onClick={handleclear}
        >
          Clear
        </Button>
        {/* <div className="init-filter-item">
          <FilterItem
            name={"Order By"}
            list={["All"]}
            data={["All"]}
            // setData={setDriver}
            icon={<AssignmentOutlinedIcon sx={{ fontSize: "18px" }} />}
          />
        </div> */}
      </div>
    </div>
  );
};

export default InititativeFilters;
