import React, { useEffect, useState, useRef } from "react";
import InititativeTable from "./InititativeTable";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSubtab,
  changeTab,
  fetchInitiativeFinancialData,
  fetchInitiativeOverviewData,
  handleSortModel,
  changeCountry,
  changeSegment,
  changeIsAllSegment,
  changeIsAllCountry,
} from "../../redux/reducers/InitiativeSlice";
import { GenerateRowsColumn } from "../../hooks/GenerateRowsColumn";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "../../styles/tabs.style.scss";
import { CONSTANTS } from "../../data/constants";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import UseLocalStorage from "../../hooks/useLocalStorage";

const InitTabs = [
  { label: "Initiative Overview", value: "initiative-overview", row_length: 8 },
  { label: "Initiatives KPI's", value: "imm-financial", row_length: 8 },
  // { label: "Extended View", value: "extended-view", row_length: 0 },
];

const InitSubTabs = [
  { label: "IMM Impact vs Plan", value: "imm-impact-vs-plan" },
  { label: "IMM Impanct vs Latest QF", value: "imm-impact-vs-latest-qf" },
];

const InitiativeTabs = () => {
  const dispatch = useDispatch();
  const currentYear = CONSTANTS?.currentYear;
  const localStorageType = UseLocalStorage("selectedType");
  const localStorageValue = UseLocalStorage("selectedValue");
  const prevLocalStorageValue = useRef(localStorageValue);

  const {
    currentTab,
    currentSubTab,
    overviewStatus,
    overviewTableRowLength,
    financialTableRowLength,
    overviewPageNo,
    pageLimit,
    financialStatus,
    sortModel,
    overviewPaginationModel,
    overviewTableData,
    financialTableData,
    currentSector,
    currentLever,
    currentDriver,
    isAllSector,
    isAllLever,
    isAllDriver,
    sectorList,
    leverList,
    driverList,
    segmentList,
    countryList,
    selectedInitiative,
    selectedOwner,
    selectedSecondOwner,
    selectedFinancialOwner,
    selectedIsValid,
    selectedComplexity,
    selectedLog,
    selectedClassification,
    selectedTags,
    selectedStatus,
    selectedGPCurrentYearMin,
    selectedGPCurrentYearMax,
    selectedGPNextYearMin,
    selectedGPNextYearMax,
    selectedGPYearAfterNextMin,
    selectedGPYearAfterNextMax,
    currentCountry,
    currentSegment,
    filtersData,
    isAllCountry,
    isAllSegment
  } = useSelector((state) => state.InitiativeSlice);
  const [localCurrentTab, setLocalCurrentTab] = useState("");
  const { rows, columns } = GenerateRowsColumn(
    currentTab,
    sortModel,
    overviewPaginationModel
  );

  const [loadingDownload, setLoadingDownload] = useState(false);
  const handleTabChange = (tab) => {
    dispatch(handleSortModel(`gp_${currentYear}`));
    dispatch(changeTab(tab));
  };

  const handleSubtabChange = (subtab) => {
    dispatch(changeSubtab(subtab));
  };

  // console.log("Table Status :::", overviewStatus)

  useEffect(() => {
    const dynamicKey = localStorageType === "country" ? "segment" : "country";
    const dynamicValue =
      localStorageType === "country" ? currentSegment : currentCountry;
    const logsCountrySegment =
      prevLocalStorageValue.current !== localStorageValue
        ? dynamicKey === "country"
          ? countryList
          : segmentList
        : dynamicValue;
    if (prevLocalStorageValue.current !== localStorageValue) {
      // Update the state here
      dispatch(changeSegment([]));
      dispatch(changeCountry([]));
      dispatch(changeIsAllSegment(true));
      dispatch(changeIsAllCountry(true));
      // Update the reference value
      prevLocalStorageValue.current = localStorageValue;
    }
    let filterObj = {
      sector: !isAllSector
        ? currentSector?.length && currentSector
        : sectorList,
      lever: !isAllLever ? currentLever?.length && currentLever : leverList,
      driver: !isAllDriver
        ? currentDriver?.length && currentDriver
        : driverList,
      [`gp_${currentYear}_min`]:
        selectedGPCurrentYearMin > 0 ? selectedGPCurrentYearMin : null,
      [`gp_${currentYear}_max`]:
        selectedGPCurrentYearMax > 0 ? selectedGPCurrentYearMax : null,
      [`gp_${currentYear + 1}_min`]:
        selectedGPNextYearMin > 0 ? selectedGPNextYearMin : null,
      [`gp_${currentYear + 1}_max`]:
        selectedGPNextYearMax > 0 ? selectedGPNextYearMax : null,
      [`gp_${currentYear + 2}_min`]:
        selectedGPYearAfterNextMin > 0 ? selectedGPYearAfterNextMin : null,
      [`gp_${currentYear + 2}_max`]:
        selectedGPYearAfterNextMax > 0 ? selectedGPYearAfterNextMax : null,
      [`${localStorageType}`]:
        localStorageValue === "Global" ? [] : [localStorageValue],
      [dynamicKey]:logsCountrySegment,
      // ...(localStorageType === 'segment' ? { segmentCode } : {}),
      initiative: selectedInitiative,
      owner: selectedOwner,
      delegate: selectedSecondOwner,
      financial_owner: selectedFinancialOwner,
      status: selectedStatus,
      complexity: selectedComplexity,
      inv_type:
        selectedLog === "All"
          ? ["OPPORTUNITY", "INITIATIVE"]
          : [selectedLog?.toUpperCase()],
      is_valid: selectedIsValid === "All" ? ["Yes", "No"] : [selectedIsValid],
    };

    let postData = {
      pageNo: overviewPaginationModel.page + 1,
      limit: overviewPaginationModel.pageSize,
      sortBy: sortModel?.length
        ? sortModel[0]?.field == "name"
          ? "initiative"
          : sortModel[0]?.field
        : `gp_${currentYear}`,
      direction: sortModel?.length ? sortModel[0]?.sort : "desc",
      filters: filterObj,
    };

    setLocalCurrentTab(currentTab);
    if (localStorageType && localStorageValue !== "undefined") {
      if (currentTab === "initiative-overview") {
        dispatch(fetchInitiativeOverviewData(postData));
      }
      if (currentTab === "imm-financial") {
        dispatch(fetchInitiativeFinancialData(postData));
      }
    }
  }, [
    currentTab,
    // dispatch,
    sortModel,
    overviewPaginationModel,
    segmentList,
    countryList,
    // selectedInitiative,
    // selectedOwner,
    // selectedSecondOwner,
    // selectedFinancialOwner,
    // selectedIsValid,
    // selectedComplexity,
    // selectedClassification,
    // selectedTags,
    // selectedStatus,
    // selectedGP2024Min,
    // selectedGP2024Max,
    // selectedGP2025Min,
    // selectedGP2025Max,
    // selectedGP2026Min,
    // selectedGP2026Max,
  ]);

  const downloadTableData = async () => {
    const dynamicKey = localStorageType === "country" ? "segment" : "country";
    const dynamicValue =
      localStorageType === "country" ? currentSegment : currentCountry;
    setLoadingDownload(true);
    let apiURL =
      currentTab === "initiative-overview"
        ? `${process.env.REACT_APP_API_BASE_URL}/api/home/initiative-overview/export`
        : `${process.env.REACT_APP_API_BASE_URL}/api/home/imm-financial/export`;
    try {
      const idToken = localStorage.getItem("token");
      const response = await axios.post(
        apiURL,
        {
          limit: overviewPaginationModel.pageSize,
          sortBy: sortModel?.length
            ? sortModel[0]?.field == "name"
              ? "initiative"
              : sortModel[0]?.field
            : `gp_${currentYear}`,
          direction: sortModel?.length ? sortModel[0]?.sort : "desc",
          filters: {
            sector: isAllSector ? [] : currentSector,
            lever: isAllLever ? [] : currentLever,
            driver: isAllDriver ? [] : currentDriver,
            [`gp_${currentYear}_min`]:
              selectedGPCurrentYearMin > 0 ? selectedGPCurrentYearMin : null,
            [`gp_${currentYear}_max`]:
              selectedGPCurrentYearMax > 0 ? selectedGPCurrentYearMax : null,
            [`gp_${currentYear + 1}_min`]:
              selectedGPNextYearMin > 0 ? selectedGPNextYearMin : null,
            [`gp_${currentYear + 1}_max`]:
              selectedGPNextYearMax > 0 ? selectedGPNextYearMax : null,
            [`gp_${currentYear + 2}_min`]:
              selectedGPYearAfterNextMin > 0
                ? selectedGPYearAfterNextMin
                : null,
            [`gp_${currentYear + 2}_min`]:
              selectedGPYearAfterNextMax > 0
                ? selectedGPYearAfterNextMax
                : null,
            initiative: selectedInitiative,
            [`${localStorageType}`]:
            localStorageValue === "Global" ? [] : [localStorageValue],
          [dynamicKey]:
            dynamicKey === "country"
              ? !isAllCountry
                ? dynamicValue?.length && dynamicValue
                : countryList
              : !isAllSegment
              ? dynamicValue?.length && dynamicValue
              : segmentList,
            owner: selectedOwner,
            delegate: selectedSecondOwner,
            financial_owner: selectedFinancialOwner,
            status: selectedStatus,
            complexity: selectedComplexity,
            inv_type:
              selectedLog === "All"
                ? ["OPPORTUNITY", "INITIATIVE"]
                : [selectedLog?.toUpperCase()],
            is_valid:
              selectedIsValid === "All" ? ["Yes", "No"] : [selectedIsValid],
          },
        },
        {
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = await response.data.data;
      const filename =
        currentTab == "initiative-overview"
          ? `Initiative Overview_${new Date()}`
          : `Initiative KPI's_${new Date()}`;
      // Convert JSON data to a worksheet
      console.log("Response download", data);
      const worksheet = XLSX.utils.json_to_sheet(data);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate a binary Excel file
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the binary data
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Trigger the file download using FileSaver
      saveAs(blob, `${filename}.xlsx`);
      // const url = window.URL.createObjectURL(new Blob([data], {type: 'text/csv;charset=utf-8'}));
      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `${filename}.csv`);
      // document.body.appendChild(link);
      // link.click();
      setLoadingDownload(false);
      // console.log("PBI token resp ::", data);
    } catch (error) {
      setLoadingDownload(false);
      console.error("Error while fetching PBI token...", error);
    }
  };

  return (
    <>
      <div className="initiative">
        <div className="init-tabs-list">
          {InitTabs.map((tab, index) => (
            <div
              key={index}
              className={
                localCurrentTab === tab.value ? "init-tab-active" : "init-tab"
              }
              onClick={() => handleTabChange(tab.value)}
              style={{ cursor: "pointer" }}
            >
              <p className="init-tabname">{tab.label}</p>
              {tab.row_length ? (
                <p className="init-tabrows-length">{overviewTableRowLength}</p>
              ) : null}
            </div>
          ))}
        </div>
        <button className="download-table" onClick={downloadTableData}>
          Download
          {loadingDownload && <CircularProgress size={20} />}
          <span className="download-icon">
            <FileDownloadOutlinedIcon fontSize="small" />
          </span>
        </button>
      </div>
      <div className="init-views">
        {localCurrentTab === "initiative-overview" && (
          <div className="init-table">
            <InititativeTable
              rows={overviewTableData}
              columns={columns}
              status={overviewStatus}
              rowCount={overviewTableRowLength}
            />
          </div>
        )}
        {localCurrentTab === "imm-financial" && (
          <div className="init-table">
            {/* <InitiativeFinancialTable rows={rows} columns={columns} /> */}
            <InititativeTable
              rows={financialTableData}
              columns={columns}
              columnGroup={"financial"}
              status={financialStatus}
              rowCount={overviewTableRowLength}
            />
          </div>
        )}
        {localCurrentTab === "extended-view" && (
          <div className="init-subtab-list ">
            {InitSubTabs.map((subtab) => (
              <div
                className={
                  currentSubTab === subtab.value
                    ? "init-subtab-active"
                    : "init-subtab"
                }
                onClick={() => handleSubtabChange(subtab.value)}
              >
                {subtab.label}
              </div>
            ))}
            <div className="init-table">
              {/* <InititativeTable
                rows={rows}
                columns={columns}
                columnGroup={"extended"}
              /> */}
              {/* <InitiativeExtendedTable
                rows={rows}
                columns={columns}
                // columnGroup={"extended"}
              /> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InitiativeTabs;
