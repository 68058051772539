import { useState, useEffect } from "react";
const UseLocalStorageListener = (key) => {
  const [localStorageValue, setLocalStorageValue] = useState(
    localStorage.getItem(key)
  );
  useEffect(() => {
    const handleUpdate = () => {
      setLocalStorageValue(localStorage.getItem(key));
    };
    // Listen for a custom "localStorageUpdated" event
    window.addEventListener("localStorageUpdated", handleUpdate);
    return () => {
      window.removeEventListener("localStorageUpdated", handleUpdate);
    };
  }, [key]);
  return localStorageValue;
};
export default UseLocalStorageListener;